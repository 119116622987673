import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import formatISODate from '../../../Helpers/formatISODate'
import './styles.scss'

const TrackItResult = ({ trackItResult }) => {
  return (
    <div className="track-it-result">
      <span className="st">
        <FontAwesomeIcon className="icon" icon={solid('caret-right')} />{' '}
        Shipment Tracking
      </span>

      <div className="track-it-result__content">
        <h3>AirwayBill #: {trackItResult.trackingNumber}</h3>
        <div className="track-it-result__content__up">
          <table>
            <tr>
              <th>Depositor / Sender</th>
            </tr>

            <tr>
              <td>Sender's Name: {trackItResult.senderName}</td>
            </tr>

            <tr>
              <td>Sender's Contact: {trackItResult.senderContact}</td>
            </tr>

            <tr>
              <td>Sender's Email: {trackItResult.senderEmail}</td>
            </tr>

            <tr>
              <td>Sender's Address: {trackItResult.senderAddress}</td>
            </tr>
          </table>

          <table>
            <tr>
              <th>NEXT OF KIN / RECEIVER</th>
            </tr>

            <tr>
              <td>Receiver's Name: {trackItResult.receiverName}</td>
            </tr>

            <tr>
              <td>Receiver's Contact: {trackItResult.receiverContact}</td>
            </tr>

            <tr>
              <td>Receiver's Email: {trackItResult.receiverEmail}</td>
            </tr>

            <tr>
              <td>Receiver's Address: {trackItResult.receiverAddress}</td>
            </tr>
          </table>
        </div>
        <div className="track-it-result__content__down">
          <table>
            <tr>
              <th>Date</th>

              <th>Description</th>

              <th>Current Location</th>

              <th>ETA</th>

              <th>Status</th>
            </tr>

            <tr>
              <td>
                {formatISODate(trackItResult.dispatchDate)?.split(' ')[0] ||
                  '-'}
              </td>

              <td>{trackItResult.packageDescription || '-'}</td>

              <td>{trackItResult.currentLocation || '-'}</td>

              <td>
                {formatISODate(trackItResult.estimatedDeliveryDate)?.split(
                  ' '
                )[0] || '-'}
              </td>

              <td>{trackItResult.status}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TrackItResult
