import './styles.scss'

const PageCard = ({ children }) => {
  return (
    <div className="page-card">
      <div className="page-card__body"> {children}</div>
    </div>
  )
}

export default PageCard
