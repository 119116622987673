import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from '../Components/Layout'
import ScrollToTop from '../Components/ScrollToTop'
import { ROUTES } from '../constants'
import AboutUs from '../Pages/AboutUs'
import AdminLogin from '../Pages/Admin/Login'
import AdminDashboard from '../Pages/Admin/Dashboard'
import ContactUs from '../Pages/ContactUs'
import Home from '../Pages/Home'
import Service from '../Pages/Service'
import TrackIt from '../Pages/TrackIt'
import { RequireAuth } from '../HoC/RequireAuth'
import AdminLayout from '../Components/AdminLayout'
import Settings from '../Pages/Admin/Settings'
import AddTracking from '../Pages/Admin/Tracking'

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={ROUTES.about} element={<AboutUs />} />
          <Route path={ROUTES.contact} element={<ContactUs />} />
          <Route path={ROUTES.services} element={<Service />} />
          <Route path={ROUTES.trackIt} element={<TrackIt />} />
        </Route>

        <Route path={ROUTES.admin.login} element={<AdminLogin />} />

        <Route
          path={ROUTES.admin.index}
          element={
            <RequireAuth>
              <AdminLayout />
            </RequireAuth>
          }
        >
          <Route index element={<AdminDashboard />} />
          <Route path={ROUTES.admin.add_tracking} element={<AddTracking />} />
          <Route path={ROUTES.admin.settings} element={<Settings />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
