import './styles.scss'
import PageCard from '../../../Components/PageCard'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectRefreshToken,
  selectToken,
} from '../../../ReduxStore/features/authSlice'
import TrackingService from '../../../Services/Tracking'
import {
  selectTrackingSliceData,
  trackingSliceActions,
} from '../../../ReduxStore/features/trackingSlice'
import logoutIfNoAuth from '../../../Helpers/logoutIfNoAuth'
import { useEffect, useState } from 'react'
import formatISODate from '../../../Helpers/formatISODate'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../constants'

const defaultConfirmOptiions = {
  title: 'Confirm Delete',
  message: 'Do you really want to delete this?',
  closeOnEscape: true,
  closeOnClickOutside: true,
  keyCodeForClose: [8, 32],
  buttons: [
    {
      label: 'Yes',
      onClick: () => {},
    },
    {
      label: 'No',
      onClick: () => {},
    },
  ],
}

const AdminDashboard = () => {
  const token = useSelector(selectToken)
  const refresh_token = useSelector(selectRefreshToken)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [deleting, setDeleting] = useState(null)
  const deleteTracking = (id) => async () => {
    if (deleting) return

    setDeleting(id)

    try {
      await TrackingService.remove(token, id)

      dispatch(trackingSliceActions.remove(id))
    } catch (error) {
      console.error('Error', error)

      logoutIfNoAuth(error, dispatch, { access_token: token, refresh_token })
    }

    setDeleting(null)
  }

  const handleDelete = (id) => () => {
    const buttons = [...defaultConfirmOptiions.buttons]
    buttons[0].onClick = deleteTracking(id)
    confirmAlert({ ...defaultConfirmOptiions, buttons })
  }

  const goToUpdate = (selectedTracking) => () => {
    if (deleting) return
    navigate(ROUTES.admin.add_tracking, { state: { selectedTracking } })
  }

  const { data, initialized } = useSelector(selectTrackingSliceData)

  const fetchTrackings = async () => {
    try {
      const res = await TrackingService.get_all(token)

      dispatch(
        trackingSliceActions.init(
          res.data.data.map((item) => ({
            ...item,
            dispatchDate: formatISODate(item.dispatchDate).split(' ')[0],
            estimatedDeliveryDate: formatISODate(
              item.estimatedDeliveryDate
            ).split(' ')[0],
          }))
        )
      )
    } catch (error) {
      logoutIfNoAuth(error, dispatch, { access_token: token, refresh_token })
    }
  }

  useEffect(() => {
    if (!initialized && token) {
      fetchTrackings()
    }
  }, [initialized, token])

  return (
    <div className="admin-dashboard">
      {/* Admin Dashboard <button onClick={handleLogout}>Logout</button> */}

      <PageCard>
        <h4>TRACKERS</h4>

        <div className="table-responsive">
          <table className="data-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Tracking Number</th>
                <th>Status</th>
                <th>Date Added</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>

            <tbody>
              {data.map((item, idx) => (
                <tr key={item.id}>
                  <td>{idx + 1}</td>

                  <td>{item.trackingNumber}</td>

                  <td>{item.status}</td>

                  <td>{formatISODate(item.createdAt)}</td>

                  <td>
                    <button className="update" onClick={goToUpdate(item.id)}>
                      Update
                    </button>
                  </td>

                  <td>
                    <button className="delete" onClick={handleDelete(item.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </PageCard>
    </div>
  )
}

export default AdminDashboard
