import CustomButton from '../../../Components/CustomButton'
import './styles.scss'

const InfoCard = ({ onClick, text, btnLabel }) => {
  return (
    <div className="home-page__info-card">
      <div>{text}</div>

      <CustomButton label={btnLabel} onClick={onClick} />
    </div>
  )
}

export default InfoCard
