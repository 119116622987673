import axios from 'axios'
import { API_URL } from '../constants'

class TrackingService {
  static async create(token, data) {
    return axios.post(`${API_URL}/track-item`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  static async update(token, data) {
    return axios.put(`${API_URL}/track-item`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  static async get_all(token) {
    return axios.get(`${API_URL}/track-item`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  static async get_by_tracking_number(num) {
    return axios.get(`${API_URL}/track-item/view?num=${num}`)
  }

  static async remove(token, id) {
    return axios.delete(`${API_URL}/track-item?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }
}

export default TrackingService
