import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  initialized: false,
}

export const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    init: (state, { payload }) => {
      state.data = payload
      state.initialized = false
    },

    add: (state, { payload }) => {
      state.data = [...state.data, payload]
    },

    update: (state, { payload }) => {
      state.data = state.data.map((tracking) => {
        if (tracking.id === payload.id) {
          return { ...tracking, ...payload }
        }

        return tracking
      })
    },

    remove: (state, { payload }) => {
      state.data = state.data.filter((tracking) => tracking.id !== payload)
    },
  },
})

export const trackingSliceActions = trackingSlice.actions

export const selectTrackingSliceData = (state) => state.tracking

export default trackingSlice.reducer
