import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import AnimateHeight from 'react-animate-height'
import CustomAlert from '../../../Components/Alert'
import CustomButton from '../../../Components/CustomButton'
import fetchApiError from '../../../Helpers/fetchApiError'
import { useForm } from '../../../Hooks/useForm'
import TrackingService from '../../../Services/Tracking'
import './styles.scss'

const Tracker = ({ setTrackItResult }) => {
  const { form, onChange, reset } = useForm({
    trackingNumber: '',
    loading: false,
    error: null,
  })

  useEffect(() => {
    return reset
  }, [])

  const getDetails = async (num) => {
    onChange('loading', true)
    onChange('error', null)

    try {
      const res = await TrackingService.get_by_tracking_number(num)

      setTrackItResult(res.data.data)
    } catch (error) {
      setTrackItResult(null)

      onChange('error', fetchApiError(error))
    }

    onChange('loading', false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    getDetails(form.trackingNumber)
  }

  return (
    <div className="tracker">
      <span className="st">
        <FontAwesomeIcon className="icon" icon={solid('caret-right')} />{' '}
        Shipment Tracking
      </span>

      <form onSubmit={handleSubmit} className="form">
        <h3>Track It! here</h3>

        <input
          type="text"
          className="airwaybill-input"
          placeholder="INSERT AIRWAYBILL #"
          value={form.trackingNumber}
          onChange={(e) => onChange('trackingNumber', e.target.value)}
          required
        />

        <AnimateHeight duration={300} height={form.error ? 'auto' : 0}>
          <CustomAlert variant="danger" message={form.error} />
        </AnimateHeight>

        <CustomButton
          loading={form.loading}
          otherClassName={'track-it'}
          label={'Track IT!'}
        />
      </form>
    </div>
  )
}

export default Tracker
