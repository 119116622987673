import './styles.scss'

const CustomAlert = ({ variant = 'info', message }) => {
  return (
    <div className="custom-alert">
      <div className={`custom-alert__alert custom-alert__${variant}`}>
        {message}
      </div>
    </div>
  )
}

export default CustomAlert
