import './styles.scss'

const InputArea = ({ title, ...props }) => {
  return (
    <div className="custom-input-area">
      <div className="label">{title}</div>

      <textarea {...props} />
    </div>
  )
}

export default InputArea
