import { useEffect, useRef, useState } from 'react'
import './styles.scss'

const delay = 2500

const SlideShow = ({ slideImages = [] }) => {
  const [index, setIndex] = useState(0)
  const timeoutRef = useRef(null)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === slideImages.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    )

    return () => {
      resetTimeout()
    }
  }, [index])

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {slideImages.map((img, index) => (
          <div key={index} className="slide">
            <img src={img} alt="" className="slide" />
          </div>
        ))}
      </div>

      <div className="slideshowDots">
        {slideImages.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot slideshowDot-${
              index === idx ? 'active' : undefined
            }`}
            onClick={() => {
              setIndex(idx)
            }}
          ></div>
        ))}
      </div>
    </div>
  )
}

export default SlideShow
