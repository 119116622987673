import ServiceCardV2 from '../ServiceCardV2'
import './styles.scss'

import missionIcon from '../../Assets/Images/mission-icon.png'
import vissionIcon from '../../Assets/Images/vision-icon.png'
import contactUsIcon from '../../Assets/Images/contact-us-icon.png'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants'

const UnbeatableSection = () => {
  const navigate = useNavigate()

  const goToAboutUs = () => navigate(ROUTES.about)

  const goToContactUs = () => navigate(ROUTES.contact)

  const goToTrackIt = () => navigate(ROUTES.trackIt)

  return (
    <section className="unbeatable-section">
      <div className="info">
        <h2>Unbeatable Courier Services</h2>

        <p>
          When you need it there today and not overnight, your shipment will be
          picked up and delivered across Maryland or out of state on a direct
          delivery.
        </p>
      </div>

      <div className="service-cards">
        <ServiceCardV2
          label="Tracking"
          icon={<img src={missionIcon} />}
          onClick={goToTrackIt}
        />

        <ServiceCardV2
          label="Read More"
          icon={<img src={vissionIcon} />}
          onClick={goToAboutUs}
        />

        <ServiceCardV2
          label="Contact Us"
          icon={<img src={contactUsIcon} />}
          onClick={goToContactUs}
        />
      </div>
    </section>
  )
}

export default UnbeatableSection
