import { useEffect, useState } from 'react'

export const useScrollPosition = () => {
  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 })

  useEffect(() => {
    const updatePosition = () => {
      const layout = document.querySelector('.layout')

      if (!layout) return

      setPosition(layout.scrollTop)
    }

    const layout = document.querySelector('.layout')

    if (!layout) return

    layout.addEventListener('scroll', updatePosition)
    updatePosition()

    return () => layout.removeEventListener('scroll', updatePosition)
  }, [])

  return scrollPosition
}
