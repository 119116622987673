import './styles.scss'

const Input = ({ title, ...props }) => {
  return (
    <div className="custom-input">
      <div className="label">{title}</div>

      <input {...props} />
    </div>
  )
}

export default Input
