import './styles.scss'
import image from '../../../Assets/Images/quote-modal-img.jpeg'
import { CSSTransition } from 'react-transition-group'
import React, { useEffect, useRef } from 'react'
import Input from '../../Input'
import InputArea from '../../InputArea'
import CustomButton from '../../CustomButton'
import { useForm } from '../../../Hooks/useForm'
import emailjs from '@emailjs/browser'

const templateId = 'template_tqty2mv'
const serviceId = 'service_pfpb65h'
const publicId = 's6HNn9FJmskIhvYFR'

const QuoteForm = ({ showForm, handleClose, ...props }) => {
  const formRef = useRef(null)

  const handleClick = (e) => e?.stopPropagation()

  const { form, reset, onChange } = useForm({
    name: '',
    address: '',
    email: '',
    msg: '',
    loading: false,
  })

  const handleChange = (e) => onChange(e.target.name, e.target.value)

  useEffect(() => {
    return reset
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    onChange('loading', true)

    try {
      await emailjs.send(serviceId, templateId, form, publicId)

      handleClose()
    } catch (error) {
      console.log('ERROR', error)
    }

    onChange('loading', false)
  }

  return (
    <CSSTransition
      in={showForm}
      nodeRef={formRef}
      timeout={300}
      unmountOnExit
      classNames="quote-form"
    >
      <form
        ref={formRef}
        onClick={handleClick}
        className="quote-form"
        onSubmit={handleSubmit}
        {...props}
      >
        <div className="quote-form__image">
          <img src={image} alt="" />
        </div>

        <div className="quote-form__content">
          <h5 className="title">Get A Free Quote</h5>

          <div className="name">
            <Input
              title="Full Name"
              required
              placeholder="Name"
              value={form.name}
              onChange={handleChange}
              name="name"
            />

            <Input
              title="Email"
              type="email"
              required
              placeholder="Email"
              value={form.email}
              onChange={handleChange}
              name="email"
            />
          </div>

          <Input
            title="Address"
            required
            placeholder="123 Main St"
            value={form.address}
            onChange={handleChange}
            name="address"
          />

          <InputArea
            title="Message"
            required
            rows={3}
            value={form.msg}
            onChange={handleChange}
            name="msg"
          />

          <CustomButton
            loading={form.loading}
            otherClassName="submit"
            type="submit"
            label="SEND MESSAGE"
          />
        </div>
      </form>
    </CSSTransition>
  )
}

export default QuoteForm
