import { useState } from 'react'
import Accordion from '../Accordion'

const AccordionGroup = ({ data = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleClick = (idx) => () => {
    if (activeIndex === idx) {
      setActiveIndex(null)
    } else {
      setActiveIndex(idx)
    }
  }

  return (
    <div className="accordion-group">
      {data.map((accordion, idx) => (
        <Accordion
          key={`accordion-${idx}`}
          accordion={accordion}
          onClick={handleClick(idx)}
          isOpen={activeIndex === idx}
        />
      ))}
    </div>
  )
}

export default AccordionGroup
