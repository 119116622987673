import './styles.scss'
import UnbeatableSection from '../../Components/UnbeatableSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const ContactUs = () => {
  return (
    <div className="contact-us">
      <div className="contact-us__top">
        <h1>Contact Us</h1>
      </div>

      <div className="contact-us__content">
        <section className="contact-us__content__details">
          <div className="contact-us__content__details__content">
            <p>
              Whales Xpressway is available early and after
              hours to meet your messenger and courier delivery needs. Call us
              anytime for a free quote and join the ranks of satisfied
              customers.
            </p>

            <span className="label">Phone :</span>
            <div className="value">
              <FontAwesomeIcon className="icon" icon={solid('phone')} /> +1 (813) 665-0401
            </div>

            <span className="label">Address :</span>
            <div className="value">
              <FontAwesomeIcon className="icon" icon={solid('map')} /> Global
              Facility Office
            </div>

            <span className="label">Email :</span>
            <div className="value">
              <FontAwesomeIcon className="icon" icon={solid('envelope-open')} />{' '}
              business@whalesxpressway.com
            </div>
          </div>
        </section>

        <UnbeatableSection />
      </div>
    </div>
  )
}

export default ContactUs
