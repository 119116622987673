import { useState } from 'react'

export const useForm = (initialState = {}) => {
  const [form, set_form] = useState({ ...initialState })
  const [err, set_err] = useState([])

  const onChange = (name, value) =>
    set_form((prev) => ({ ...prev, [name]: value }))

  const validate = (keys) => {
    set_err([])

    for (const key of keys) {
      if (typeof form[key] === 'undefined' || form[key] === null) {
        set_err([key + ' is required.'])
        return false
      }
    }

    return true
  }

  const reset = () => set_form(initialState)

  const handleServerError = (error) => {
    const msg = error.response?.data?.message
    if (msg) {
      if (msg instanceof String || typeof msg === 'string') set_err([msg])
      else {
        const arr = []
        Object.keys(msg).map((key) => {
          for (const emsg of msg[key]) arr.push(emsg)
        })

        set_err(arr)
      }
    } else {
      set_err(['Something went wrong'])
    }
  }

  return { form, onChange, reset, err, validate, handleServerError, set_err }
}
