import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../constants'
import { selectToken } from '../ReduxStore/features/authSlice'

export const RequireAuth = ({ children }) => {
  const token = useSelector(selectToken)

  const navigate = useNavigate()

  useEffect(() => {
    // console.log('Token', token)

    if (!token) {
      navigate(ROUTES.admin.login)
    }
  }, [token])

  return children
}
