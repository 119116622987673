import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import './styles.scss'

const FooterLink = ({ route = '/', label, state }) => {
  const navigate = useNavigate()

  const handleClick = () => navigate(route, { state })

  return (
    <div className="footer-link" onClick={handleClick}>
      <FontAwesomeIcon className="icon" icon={solid('angle-right')} />
      <span>{label}</span>
    </div>
  )
}

export default FooterLink
