import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './styles.scss'
import { CSSTransition } from 'react-transition-group'

const NavItem = ({ label, route, hasDropDown = false, options = [] }) => {
  const navigate = useNavigate()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleClick = () => {
    if (hasDropDown) {
      setIsMenuOpen(!isMenuOpen)
    } else {
      navigate(route)
    }
  }

  const onMenuItemSelected = (value) => {
    navigate(route, { state: { service: value } })
  }

  return (
    <div className="nav-item" onClick={handleClick}>
      <span
        onMouseEnter={() => setIsMenuOpen(true)}
        onMouseLeave={() => setIsMenuOpen(false)}
      >
        {label}{' '}
        {hasDropDown && (
          <FontAwesomeIcon className="icon" icon={solid('chevron-down')} />
        )}
      </span>

      {hasDropDown && (
        <NavItemMenu
          isMenuOpen={isMenuOpen}
          closeMenu={() => setIsMenuOpen(false)}
          openMenu={() => setIsMenuOpen(true)}
          options={options}
          handleClick={onMenuItemSelected}
        />
      )}
    </div>
  )
}

const NavItemMenu = ({
  isMenuOpen,
  options,
  handleClick,
  closeMenu,
  openMenu,
}) => {
  const menuRef = useRef(null)

  return (
    <CSSTransition
      classNames="nav-item-menu"
      in={isMenuOpen}
      timeout={300}
      unmountOnExit
      nodeRef={menuRef}
    >
      <div
        ref={menuRef}
        className="nav-item-menu"
        onMouseEnter={openMenu}
        onMouseLeave={closeMenu}
      >
        <div className="nav-item-menu__container">
          {options.map((option) => (
            <div
              className="option"
              key={option}
              onClick={() => handleClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
    </CSSTransition>
  )
}

export default NavItem
