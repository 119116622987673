import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants'
import FreightCard from './FreightCard'
import InfoCard from './InfoCard'
import './styles.scss'

import roadFreight from '../../Assets/Images/road-freight.png'
import airFreight from '../../Assets/Images/air-freight.png'
import oceanFreight from '../../Assets/Images/ocean-freight.png'

import CustomButton from '../../Components/CustomButton'
import AccordionGroup from '../../Components/AccordionGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import ServiceCard from './ServiceCard'

import gridImg1 from '../../Assets/Images/grid-1.jpeg'
import gridImg2 from '../../Assets/Images/cart-1.jpg'
import titleIcon1 from '../../Assets/Images/title-icon-1.png'
import missionIcon from '../../Assets/Images/mission-icon.png'
import vissionIcon from '../../Assets/Images/vision-icon.png'
import OurServiceSection from '../../Components/OurServiceSection'
import UnbeatableSection from '../../Components/UnbeatableSection'

const bgImages = [
  "url('images/home-slide-1.jpeg')",
  "url('images/home-slide-2.jpeg')",
  "url('images/home-slide-3.jpeg')",
]

const accordions = [
  {
    title: 'Why Us?',
    content:
      'We provide timely delivery to ensure customer satisfaction. Our delivery and courier services can fit different time and budget sensitivities, and our rates will leave you confident that you have made the right choice.',
    icon: <FontAwesomeIcon className="icon" icon={solid('circle-info')} />,
  },
  {
    title: 'Timely delivery',
    content:
      'When you need it there today and not overnight, your shipment will be picked up and delivered across Maryland or out of state on a direct delivery.',
    icon: <FontAwesomeIcon className="icon" icon={solid('truck')} />,
  },
  {
    title: 'Wharehousing Solution',
    content:
      'Falcon warehouses have immediate availability, are climate-controlled and are protected with 24-hour security. Your inventory can be available for shipment from any of our warehouses around the clock, and our professional customer service staff is always on site for immediate retrieval and preparation for urgent delivery.',
    icon: <FontAwesomeIcon className="icon" icon={solid('plane-up')} />,
  },
]

const Home = () => {
  const navigate = useNavigate()
  const [activeBgIndex, setActiveBgIndex] = useState(0)

  const navigateToAboutUs = () => navigate(ROUTES.about)

  const getNextBg = () => {
    if (activeBgIndex === 2) return 0

    return activeBgIndex + 1
  }

  const changeBg = () => {
    const divObj = document.getElementById('homePageTop')

    if (!divObj) return

    const next = getNextBg()

    const bg = bgImages[next]

    divObj.style.backgroundImage = bg

    setActiveBgIndex(next)
  }

  useEffect(() => {
    let intervalId = setInterval(changeBg, 10000)

    return () => clearInterval(intervalId)
  }, [activeBgIndex])

  const goToServices = (service) => () => {
    navigate(ROUTES.services, { state: { service } })
  }

  return (
    <div className="home-page">
      <div id="homePageTop" className="home-page__top">
        {activeBgIndex === 0 && (
          <InfoCard
            text={'We combine\nExperience\nand Expertise'}
            onClick={() => navigate(ROUTES.about)}
            btnLabel="About Us"
          />
        )}

        {activeBgIndex === 1 && (
          <InfoCard
            text={'Making\nTransportation\nFast & Save'}
            onClick={() => navigate(ROUTES.about)}
            btnLabel="About Us"
          />
        )}

        {activeBgIndex === 2 && (
          <InfoCard
            text={'You\nfocus\non your business'}
            onClick={() => navigate(ROUTES.contact)}
            btnLabel="Contact Us"
          />
        )}

        <div className="home-page__top__freights">
          <FreightCard
            src={roadFreight}
            title="Road Freight"
            content="Easy to Use and Customer Focused"
            onClick={goToServices('Ocean Freight')}
          />

          <FreightCard
            src={airFreight}
            title="Air Freight"
            content="Air Freight Services with Optional Collection"
            onClick={goToServices('Air Freight')}
          />

          <FreightCard
            src={oceanFreight}
            title="Ocean Freight"
            content="Direct consolidations covering 350 destinations"
            onClick={goToServices('Ocean Freight')}
          />
        </div>
      </div>

      <div className="home-page__content">
        <section>
          <FreightCard
            src={roadFreight}
            title="Road Freight"
            content="Easy to Use and Customer Focused"
            onClick={goToServices('Ocean Freight')}
          />

          <FreightCard
            src={airFreight}
            title="Air Freight"
            content="Air Freight Services with Optional Collection"
            onClick={goToServices('Air Freight')}
          />

          <FreightCard
            src={oceanFreight}
            title="Ocean Freight"
            content="Direct consolidations covering 350 destinations"
            onClick={goToServices('Ocean Freight')}
          />
        </section>

        <section>
          <div className="section1-col">
            <span className="title-text">
              <img src={titleIcon1} alt="" />
              Who We Are?
            </span>

            <p>
              We’re one of the leading independent shipping and logistics
              providers. Trusted since 1933 by some of the world’s leading
              companies, we provide liner and port agency services, freight
              forwarding by sea, air and...
            </p>

            <CustomButton
              label={'Read More'}
              otherClassName="read-more"
              onClick={navigateToAboutUs}
            />

            <div className="accordion-container">
              <AccordionGroup data={accordions} />
            </div>
          </div>

          <div className="section1-col">
            <div>
              <img src={gridImg1} alt="" />
            </div>

            <ServiceCard
              title="Mission"
              content="To offer high-performance solutions that would exceed customer expectations through high quality service..."
              icon={<img src={missionIcon} />}
            />

            <ServiceCard
              title="Vision"
              content="To surpass customer expectations through Innovation, Creativity and Quality..."
              icon={<img src={vissionIcon} />}
            />

            <div>
              <img src={gridImg2} alt="" />
            </div>
          </div>
        </section>

        <UnbeatableSection />

        <OurServiceSection />
      </div>
    </div>
  )
}

export default Home
