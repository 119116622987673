import './styles.scss'
import PageCard from '../../../Components/PageCard'
import { TRACKING_STATUS } from '../../../constants'
import CustomButton from '../../../Components/CustomButton'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useForm } from '../../../Hooks/useForm'
import TrackingService from '../../../Services/Tracking'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectRefreshToken,
  selectToken,
} from '../../../ReduxStore/features/authSlice'
import {
  selectTrackingSliceData,
  trackingSliceActions,
} from '../../../ReduxStore/features/trackingSlice'
import AnimateHeight from 'react-animate-height'
import CustomAlert from '../../../Components/Alert'
import logoutIfNoAuth from '../../../Helpers/logoutIfNoAuth'
import fetchApiError from '../../../Helpers/fetchApiError'
import formatISODate from '../../../Helpers/formatISODate'

const Tracking = () => {
  const { state } = useLocation()
  const token = useSelector(selectToken)
  const refresh_token = useSelector(selectRefreshToken)
  const dispatch = useDispatch()

  const { data, initialized } = useSelector(selectTrackingSliceData)

  const fetchTrackings = async () => {
    try {
      const res = await TrackingService.get_all(token)

      dispatch(
        trackingSliceActions.init(
          res.data.data.map((item) => ({
            ...item,
            dispatchDate: formatISODate(item.dispatchDate).split(' ')[0],
            estimatedDeliveryDate: formatISODate(
              item.estimatedDeliveryDate
            ).split(' ')[0],
          }))
        )
      )
    } catch (error) {
      logoutIfNoAuth(error, dispatch, { access_token: token, refresh_token })
    }
  }

  const { form, onChange, reset } = useForm({
    id: null,
    trackingNumber: null,
    status: '',
    senderName: '',
    senderContact: '',
    senderEmail: '',
    senderAddress: '',
    receiverName: '',
    receiverContact: '',
    receiverEmail: '',
    receiverAddress: '',
    dispatchLocation: '',
    currentLocation: '',
    packageDescription: '',
    dispatchDate: '',
    estimatedDeliveryDate: '',
    loading: false,
    error: null,
    success: null,
  })

  const handleChange = (e) => onChange(e.target.name, e.target.value)

  useEffect(() => {
    return reset
  }, [])

  useEffect(() => {
    if (!initialized && token) {
      fetchTrackings()
    }
  }, [initialized, token])

  useEffect(() => {
    if (state?.selectedTracking && data.length > 0) {
      const selectedTracking = data.filter(
        (tracking) => tracking.id === state.selectedTracking
      )[0]

      console.log(state, data, selectedTracking)

      if (selectedTracking) {
        Object.keys(selectedTracking).forEach((key) =>
          onChange(key, selectedTracking[key])
        )
      }
    }
  }, [state, data])

  const handleSubmit = async (e) => {
    e?.preventDefault()

    onChange('loading', true)
    onChange('error', null)
    onChange('success', null)

    try {
      let payload = {}

      Object.keys(form).forEach((key) => {
        if (form[key] === '') {
          payload[key] = null
        } else {
          payload[key] = form[key]
        }
      })

      const res = form.id
        ? await TrackingService.update(token, payload)
        : await TrackingService.create(token, payload)

      form.id
        ? dispatch(trackingSliceActions.update(res.data.data))
        : dispatch(trackingSliceActions.add(res.data.data))

      Object.keys(res.data.data).map((key) => onChange(key, res.data.data[key]))

      onChange('success', res.data.message)
    } catch (error) {
      console.error('Error', error)

      logoutIfNoAuth(error, dispatch, { access_token: token, refresh_token })

      onChange('error', fetchApiError(error))
    }

    onChange('loading', false)
  }

  return (
    <div className="admin-add-tracking">
      <PageCard>
        <h3>TRACKING NUMBER</h3>

        <input
          type="text"
          value={form.trackingNumber || '-'}
          readOnly
          className="tracking_number"
        />
      </PageCard>

      <form
        id="trackItemForm"
        onSubmit={handleSubmit}
        className="tracking-info"
      >
        <div className="sender">
          <PageCard>
            <h4>Sender's Info</h4>

            <div className="input-container">
              <div className="input-group">
                <label htmlFor="sendersName">Sender's Name</label>
                <input
                  type="text"
                  id="sendersName"
                  placeholder="Sender's Name"
                  name="senderName"
                  value={form.senderName || ''}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-group">
                <label htmlFor="sendersContact">Sender's Contact</label>
                <input
                  type="text"
                  id="sendersContact"
                  placeholder="Sender's Contact"
                  name="senderContact"
                  value={form.senderContact || ''}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-group">
                <label htmlFor="sendersEmail">Sender's Email</label>
                <input
                  type="email"
                  id="sendersEmail"
                  placeholder="Sender's Email"
                  name="senderEmail"
                  value={form.senderEmail || ''}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-group">
                <label htmlFor="sendersAddress">Sender's Address</label>
                <input
                  type="text"
                  id="sendersAddress"
                  placeholder="Sender's Address"
                  name="senderAddress"
                  value={form.senderAddress || ''}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <h4>Other Info</h4>

            <div className="input-container">
              <div className="input-group">
                <label htmlFor="status">Status</label>
                <select
                  id="status"
                  name="status"
                  value={form.status || ''}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select status
                  </option>

                  {Object.values(TRACKING_STATUS).map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input-group">
                <label htmlFor="dispatchLocation">Dispatch Location</label>
                <input
                  type="text"
                  id="dispatchLocation"
                  placeholder="Origin Port"
                  name="dispatchLocation"
                  value={form.dispatchLocation || ''}
                  onChange={handleChange}
                />
              </div>

              {form.id && (
                <div className="input-group">
                  <label htmlFor="currentLocation">Current Location</label>
                  <input
                    type="text"
                    id="currentLocation"
                    placeholder="Current Location"
                    name="currentLocation"
                    value={form.currentLocation || ''}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
          </PageCard>
        </div>
        <div className="receiver">
          <PageCard>
            <h4>Receiver's Info</h4>

            <div className="input-container">
              <div className="input-group">
                <label htmlFor="receiversName">Receiver's Name</label>
                <input
                  type="text"
                  id="receiversName"
                  placeholder="Receiver's Name"
                  name="receiverName"
                  value={form.receiverName || ''}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-group">
                <label htmlFor="receiversContact">Receiver's Contact</label>
                <input
                  type="text"
                  id="receiversContact"
                  placeholder="Receiver's Contact"
                  name="receiverContact"
                  value={form.receiverContact || ''}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-group">
                <label htmlFor="receiversEmail">Receiver's Email</label>
                <input
                  type="email"
                  id="receiversEmail"
                  placeholder="Receiver's Email"
                  name="receiverEmail"
                  value={form.receiverEmail || ''}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-group">
                <label htmlFor="receiversAddress">Receiver's Address</label>
                <input
                  type="text"
                  id="receiversAddress"
                  placeholder="Receiver's Address"
                  name="receiverAddress"
                  value={form.receiverAddress || ''}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <h4>Other Info</h4>

            <div className="input-container">
              <div className="input-group">
                <label htmlFor="packageDescription">Package description</label>
                <input
                  type="text"
                  id="packageDescription"
                  placeholder="Package Description"
                  name="packageDescription"
                  value={form.packageDescription || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="input-group">
                <label htmlFor="dispatchDate">Dispatch Date</label>
                <input
                  type="date"
                  id="dispatchDate"
                  name="dispatchDate"
                  value={form.dispatchDate || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="input-group">
                <label htmlFor="estimatedDeliveryDate">
                  Estimated Delivery Date
                </label>
                <input
                  type="date"
                  id="estimatedDeliveryDate"
                  name="estimatedDeliveryDate"
                  value={form.estimatedDeliveryDate || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
          </PageCard>
        </div>
      </form>

      <AnimateHeight duration={300} height={form.success ? 'auto' : 0}>
        <div style={{ marginTop: 16 }}>
          <CustomAlert variant="success" message={form.success} />
        </div>
      </AnimateHeight>

      <AnimateHeight duration={300} height={form.error ? 'auto' : 0}>
        <div style={{ marginTop: 16 }}>
          <CustomAlert variant="danger" message={form.error} />
        </div>
      </AnimateHeight>

      <div className="tracking-action">
        <PageCard>
          <CustomButton
            label={form.id ? 'Update' : 'Add'}
            type="submit"
            form="trackItemForm"
            loading={form.loading}
          />
        </PageCard>
      </div>
    </div>
  )
}

export default Tracking
