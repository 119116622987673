import './styles.scss'

const FreightCard = ({ onClick, src, title, content }) => {
  return (
    <div className="home-page__freight-card" onClick={onClick}>
      <div className="home-page__freight-card__top">
        <img src={src} alt="" />

        <span>{title}</span>
      </div>

      <div className="home-page__freight-card__content">{content}</div>
    </div>
  )
}

export default FreightCard
