import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function ScrollToTop() {
  const location = useLocation()
  useEffect(() => {
    const layout = document.querySelector('.layout')

    layout?.scrollTo({ top: 0, behavior: 'auto' })
  }, [location])

  return null
}

export default ScrollToTop
