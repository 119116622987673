import './styles.scss'

const ServiceCard = ({ title, icon, content }) => {
  return (
    <div className="service-card">
      <div className="service-card__icon">{icon}</div>

      <h5>{title}</h5>

      <div className="service-card__divider" />

      <p>{content}</p>
    </div>
  )
}

export default ServiceCard
