import './styles.scss'
import PageCard from '../../../Components/PageCard'
import CustomButton from '../../../Components/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { logout, selectToken } from '../../../ReduxStore/features/authSlice'
import { useForm } from '../../../Hooks/useForm'
import { useMemo } from 'react'
import fetchApiError from '../../../Helpers/fetchApiError'
import AuthService from '../../../Services/Auth'
import CustomAlert from '../../../Components/Alert'
import AnimateHeight from 'react-animate-height'

const Settings = () => {
  const token = useSelector(selectToken)
  const dispatch = useDispatch()

  const { form, onChange } = useForm({
    password: '',
    confirmPassword: '',
    loading: false,
    error: null,
    success: null,
  })

  const handleChange = (e) => onChange(e.target.name, e.target.value)

  const submitDisabled = useMemo(() => {
    if (!form.password || !form.confirmPassword) return true

    if (form.password !== form.confirmPassword) return true

    return false
  }, [form.password, form.confirmPassword])

  const handleSubmit = async (e) => {
    e?.preventDefault()

    onChange('loading', true)
    onChange('error', null)
    onChange('success', null)

    try {
      const res = await AuthService.changePassword(token, form)

      onChange('success', res.data.message)
    } catch (error) {
      console.error('Error', error)

      onChange('error', fetchApiError(error))
    }

    onChange('loading', false)
  }

  return (
    <div className="admin-settings">
      <PageCard>
        <h3>Change Password</h3>

        <form onSubmit={handleSubmit}>
          <AnimateHeight
            duration={300}
            height={form.success != null ? 'auto' : 0}
          >
            <CustomAlert variant="success" message={form.success} />
          </AnimateHeight>

          <AnimateHeight
            duration={300}
            height={form.error != null ? 'auto' : 0}
          >
            <CustomAlert variant="danger" message={form.error} />
          </AnimateHeight>

          <div className="input-container">
            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                placeholder="Admin Password"
                name="password"
                value={form.password}
                onChange={handleChange}
                required
              />
            </div>

            <div className="input-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                placeholder="Confirm Admin Password"
                name="confirmPassword"
                value={form.confirmPassword}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <CustomButton
            disabled={submitDisabled}
            loading={form.loading}
            type="submit"
            label={'Save'}
            otherClassName="save"
          />
        </form>
      </PageCard>
    </div>
  )
}

export default Settings
