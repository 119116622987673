import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import './styles.scss'
import logo1 from '../../Assets/Images/whales.png'
import { ROUTES } from '../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import defaultAvatar from '../../Assets/Images/default_avatar.jpeg'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  logout,
  selectRefreshToken,
  selectToken,
} from '../../ReduxStore/features/authSlice'
import AnimateHeight from 'react-animate-height'
import AuthService from '../../Services/Auth'

const AdminLayout = () => {
  const { pathname } = useLocation()

  const access_token = useSelector(selectToken)
  const refresh_token = useSelector(selectRefreshToken)

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const handleLogout = async () => {
    try {
      AuthService.logout({ access_token, refresh_token })
      dispatch(logout())
    } catch (error) {}
  }

  const [menuOpen, setMenuOpen] = useState(false)
  const [openMobileMenu, setOpenMobileMenu] = useState(false)

  const toggleMenu = (e) => {
    e?.stopPropagation()
    setOpenMobileMenu(false)
    setMenuOpen(!menuOpen)
  }

  const toggleMobileMenu = (e) => {
    e?.stopPropagation()
    setMenuOpen(false)
    setOpenMobileMenu(!openMobileMenu)
  }

  return (
    <div
      className="admin-layout"
      onClick={() => {
        setMenuOpen(false)
        setOpenMobileMenu(false)
      }}
    >
      <div className="admin-layout__header-top">
        <div></div>

        <img src={logo1} alt="logo" className="logo" />

        <div className="profile" onClick={toggleMenu}>
          <span>Admin</span>

          <img src={defaultAvatar} alt="avatar" className="avatar" />

          <FontAwesomeIcon className="icon" icon={solid('chevron-down')} />

          {menuOpen && (
            <div className="menu-list" onClick={(e) => e.stopPropagation()}>
              <div className="option" onClick={handleLogout}>
                <FontAwesomeIcon icon={solid('sign-out')} className="icon" />
                <span>Logout</span>
              </div>
            </div>
          )}
        </div>

        <div className="mobile-menu">
          <FontAwesomeIcon
            icon={solid('bars')}
            className="icon"
            onClick={toggleMobileMenu}
          />

          <AnimateHeight duration={300} height={openMobileMenu ? 'auto' : 0}>
            <div className="list">
              <div
                className={`nav-item ${
                  pathname === ROUTES.admin.index
                    ? 'nav-item-active'
                    : undefined
                }`}
                onClick={() => navigate(ROUTES.admin.index)}
              >
                <FontAwesomeIcon className="icon" icon={solid('file-lines')} />

                <span>Dashboard</span>
              </div>

              <div
                className={`nav-item ${
                  pathname === ROUTES.admin.add_tracking
                    ? 'nav-item-active'
                    : undefined
                }`}
                onClick={() => navigate(ROUTES.admin.add_tracking)}
              >
                <FontAwesomeIcon className="icon" icon={solid('table-cells')} />

                <span>Add Tracking</span>
              </div>

              <div
                className={`nav-item ${
                  pathname === ROUTES.admin.settings
                    ? 'nav-item-active'
                    : undefined
                }`}
                onClick={() => navigate(ROUTES.admin.settings)}
              >
                <FontAwesomeIcon className="icon" icon={solid('gear')} />

                <span>Settings</span>
              </div>
            </div>
          </AnimateHeight>
        </div>
      </div>

      <div className="admin-layout__header-bottom">
        <div
          className={`nav-item ${
            pathname === ROUTES.admin.index ? 'nav-item-active' : undefined
          }`}
          onClick={() => navigate(ROUTES.admin.index)}
        >
          <FontAwesomeIcon className="icon" icon={solid('file-lines')} />

          <span>Dashboard</span>
        </div>

        <div
          className={`nav-item ${
            pathname === ROUTES.admin.add_tracking
              ? 'nav-item-active'
              : undefined
          }`}
          onClick={() => navigate(ROUTES.admin.add_tracking)}
        >
          <FontAwesomeIcon className="icon" icon={solid('table-cells')} />

          <span>Add Tracking</span>
        </div>

        <div
          className={`nav-item ${
            pathname === ROUTES.admin.settings ? 'nav-item-active' : undefined
          }`}
          onClick={() => navigate(ROUTES.admin.settings)}
        >
          <FontAwesomeIcon className="icon" icon={solid('gear')} />

          <span>Settings</span>
        </div>
      </div>

      <div className="admin-layout__outlet">
        <Outlet />
      </div>

      <div className="admin-layout__footer">
        Copyright ©Whales Xpressway {new Date().getFullYear()} All rights reserved.
      </div>
    </div>
  )
}

export default AdminLayout
