import './styles.scss'

const ServiceCardV2 = ({ icon, label, onClick }) => {
  return (
    <div className="service-card-v2" onClick={onClick}>
      {icon}

      <span className="label">{label}</span>
    </div>
  )
}

export default ServiceCardV2
