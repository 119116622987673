import './styles.scss'
import { useState } from 'react'
import Tracker from './Tracker'
import TrackItResult from './TrackItResult'

const TrackIt = () => {
  const [trackItResult, setTrackItResult] = useState(null)

  return (
    <div className="track-it">
      <div className="track-it__top">
        <h1>Track your Package</h1>
      </div>

      <div className="track-it__content">
        {!trackItResult ? (
          <Tracker setTrackItResult={setTrackItResult} />
        ) : (
          <TrackItResult trackItResult={trackItResult} />
        )}
      </div>
    </div>
  )
}

export default TrackIt
