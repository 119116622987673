import { Fragment, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import './styles.scss'

const Overlay = ({ isOpen, onClose, children }) => {
  const overlayRef = useRef(null)

  return (
    <Fragment>
      <CSSTransition
        in={isOpen}
        nodeRef={overlayRef}
        timeout={500}
        unmountOnExit
        classNames="app-overlay"
      >
        <div className="app-overlay" ref={overlayRef} onClick={onClose}>
          {children}
        </div>
      </CSSTransition>
    </Fragment>
  )
}

export default Overlay
