import { Fragment } from 'react'
import './styles.scss'
import AnimateHeight from 'react-animate-height'

const Accordion = ({ isOpen = false, accordion, onClick }) => {
  return (
    <Fragment>
      <div className="accordion">
        <div className="accordion__header" onClick={onClick}>
          {accordion.icon}

          <span>{accordion.title}</span>
        </div>

        <AnimateHeight duration={300} height={isOpen ? 'auto' : 0}>
          <div className="accordion__content">{accordion.content}</div>
        </AnimateHeight>
      </div>
    </Fragment>
  )
}

export default Accordion
