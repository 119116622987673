import { RotatingLines } from 'react-loader-spinner'
import './styles.scss'

const CustomButton = ({
  label,
  loading = false,
  otherClassName = undefined,
  ...props
}) => {
  return (
    <button
      disabled={loading}
      className={`custom-button ${otherClassName}`}
      {...props}
    >
      {loading ? (
        <RotatingLines
          strokeColor="#000"
          strokeWidth="2"
          animationDuration="0.75"
          width="5%"
          visible={true}
        />
      ) : (
        label
      )}
    </button>
  )
}

export default CustomButton
