import axios from 'axios'
import { API_URL } from '../constants'

class AuthService {
  static login(data) {
    return axios.post(`${API_URL}/auth/login`, data)
  }

  static logout(data) {
    return axios.post(`${API_URL}/auth/logout`, data)
  }

  static changePassword(token, data) {
    return axios.post(`${API_URL}/auth/change-password`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
  }
}

export default AuthService
