import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

import roadFreightDark from '../../Assets/Images/road-freight-dark.png'
import airFreightDark from '../../Assets/Images/air-freight-dark.png'
import oceanFreightDark from '../../Assets/Images/ocean-freight-dark.png'
import pAndSDark from '../../Assets/Images/p-s-dark.png'
import about2 from '../../Assets/Images/about-2.jpg'
import { SERVICES, ROUTES } from '../../constants'
import { useNavigate } from 'react-router-dom'

const OurServiceSection = () => {
  const navigate = useNavigate()

  const goToServices = (service) => () =>
    navigate(ROUTES.services, { state: { service } })

  return (
    <section className="our-service-section">
      <div className="title">
        <FontAwesomeIcon className="icon" icon={solid('truck')} />

        <div className="h2">
          <h2>Our Service</h2>
        </div>

        <div className="des">
          Despite the size and diversity of our services, our simple, effective,
          people focused approach never waivers.
        </div>
      </div>

      <div className="lko">
        <div className="lko-1">
          <div>
            <img src={roadFreightDark} alt="" />

            <h5 onClick={goToServices(SERVICES[2])}>Cargo Transportation</h5>

            <span className="des">
              Our Cargo Terminal offers competitive fares for cargo operation
              thanks to the absence of intermediaries.
            </span>
          </div>

          <div>
            <img src={airFreightDark} alt="" />

            <h5 onClick={goToServices(SERVICES[0])}>Air Freight</h5>

            <span className="des">
              Our global network has the power to help businesses grow – based
              on years of experience and...
            </span>
          </div>
        </div>

        <div className="lko-2">
          <img src={about2} alt="" />
        </div>

        <div className="lko-3">
          <div>
            <img src={oceanFreightDark} alt="" />

            <h5 onClick={goToServices(SERVICES[1])}>Ocean Freight</h5>

            <span className="des">
              Our wide experience in sea freight transport allow us to offer
              quality services for your imports...
            </span>
          </div>

          <div>
            <img src={pAndSDark} alt="" />

            <h5 onClick={goToServices(SERVICES[3])}>Packaging & Storage</h5>

            <span className="des">
              In Need of Warehouse Capacity and Open Yard Storage?
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurServiceSection
